<template>
  <div class="container">
    <!-- ref：唯一标识  绑定表单的唯一标识符loginForm  -->
    <el-form :model="formData" :rules="rules" ref="loginForm" label-width="50px" class="demo-ruleForm" hide-required-asterisk>
      <h2>十分治愈 - 后台登录</h2>
      <!--  label：左侧提示文本  prop；rulus里面的效验规则  需要校验的字段 -->
      <el-form-item label="账号" prop="adminName">
        <el-input v-model="formData.adminName" placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="adminPwd">
        <el-input v-model="formData.adminPwd" placeholder="请输入密码" show-adminPwd prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm()">登录</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      formData: {
        adminName: "",
        adminPwd: "",
      },

      rules: {
        adminName: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "账号长度在 3 到 20 个字符", //在失去焦点的时候校验
            trigger: "blur",
          },
        ],
        adminPwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 3, message: "密码长度至少6个字符", trigger: "blur" },
        ],
        /**
         * trigger: "blur"   失去焦点时候校验
         *  required  是否必须选择
         *  */
      },
    };
  },

  methods: {
    // 登陆按钮
    submitForm() {
      /**
       * 传参  绑定表单的唯一标识符loginForm   this.$refs["loginForm"]->通过$refs拿到ref为loginForm的表单对象
       * validate 校验内容是否符合校验规则     valid布尔值
       */
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          console.log("用户名:", this.formData.adminName);
          console.log("密码:", this.formData.adminPwd);
          //以下是使用axios进行登录验证
            this.axios.get("/user/login", {
                params: {
                    username: this.formData.adminName,
                    password: this.formData.adminPwd
                }, // params：配置对象  this.formData键值对数据
              })
              .then((resp) => {
                console.log(resp);
                if (resp.data.code !== 200) {
                  this.$message({
                    showClose: true,
                    message: "用户名或密码错误!",
                    type: "error",
                  });
                }else{
                  // 将登录成功的用户信息存储在sessionStorage中
                  // JSON.stringify() 将一个对象转换成为字符串形式表示
                  sessionStorage.setItem("user", JSON.stringify(resp.data.data));
                  this.$router.replace({
                    name: "jingxuan",
                  });
                }
              });
          } else {
            // 账号密码格式校验失败
            this.$message({
              showClose: true,
              message: "请输入正确的账号和密码！",
              type: "error",
            });
          return false;
        }
      });
    },

    //重置表单
    resetForm() {
      this.$refs["loginForm"].resetFields(); //重置表单
    },
  },
};
</script>

<style scoped>
.container {
  /*
    100vh  占满整个屏幕  
  */
  height: 100vh;
  background: url("../assets/images/bg.jpg") no-repeat center;
  /* 解决外边距塌陷问题  */
  overflow: hidden;
}

.el-form {
  width: 450px;
  margin: 150px auto;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background: rgba(255, 255, 255, 0.8);
}

h2 {
  text-align: center;
}
</style>
