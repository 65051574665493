<script>
export default {
    name: "LeftMenuView",
    data() {
        return {
            // 键是一级标题，值是二级标题
            menuOnj: {
                '管理员': ['用户管理', '公司信息管理'],
                // '咨询师': ['心理评测管理', '心理知识管理', 'FM管理'],
            },
            menuOnj1:{
                '咨询师': ['心理评测管理', '心理知识管理', 'FM管理'],
            },
            // 点击二级标题切换组件 ，将二级标题作为键值对的键名
            // 点击二级标题  查找对应的路由路径
            routeObj: {
                '用户管理': '/admin/user',
                '公司信息管理': '/admin/company',
                '心理评测管理': '/zixunteacher/xinliceping',
                '心理知识管理': '/zixunteacher/xinlizhishi',
                'FM管理': '/zixunteacher/fm'
            },
            navArr: [],
            userRole:''
        }
    },
    props: ['isCollapse'],
    created() {
        this.getUserRole();
    },
    methods: {
        //得到userRole
        getUserRole(){
            var userRoleJson = sessionStorage.getItem("user");
            console.log(userRoleJson)
            var parseuserRole = JSON.parse(userRoleJson);
            this.userRole = parseuserRole.role;

            console.log("parseuserRole："+this.userRole)
        },

        handleOpen: function (value) {},
        handleClose: function (value) {},
        enterGongYiZhongXinAction() {
            this.$router.push('/homeview/gongyizhongxin').catch(() => {
            });
            this.navArr = ['公益中心管理']
            this.$emit('navigateEvent', this.navArr);
        },
        enterXiLiAnswerAction() {
            this.$router.push('/xinlianswer').catch(() => {
            });
            this.navArr = ['心理答疑管理']
            this.$emit('navigateEvent', this.navArr);
        },
        enterJingXuanAction() {
            this.$router.push('/jingxuan').catch(() => {
            });
            this.navArr = ['精选文章']
            this.$emit('navigateEvent', this.navArr);
        },
        enterCourseAction() {
            this.$router.push('/course').catch(() => {
            });
            this.navArr = ['课程管理']
            this.$emit('navigateEvent', this.navArr);
        },
        enterZiXunYuYueAction() {
            this.$router.push('/zixunyuyue').catch(() => {
            });
            this.navArr = ['咨询预约']
            this.$emit('navigateEvent', this.navArr);
        },
        enterGongGaoAction() {
            this.$router.push('/gonggao').catch(() => {
            });
            this.navArr = ['公告管理']
            this.$emit('navigateEvent', this.navArr);
        },
        showComponent(key, fujik) {
            // 点击二级标题事件，将标题作为键 取出对应的路由 切换组件
            let path = this.routeObj[key];
            this.$router.push({path: path}).catch(() => {
            });
            this.navArr = [fujik, key]
            this.$emit('navigateEvent', this.navArr);
            //TODO:历史记录代写
            this.addHistory(key)
        },
        //这里的key是具体的二级菜单
        addHistory(key) {}
    }
}
</script>

<template>
    <div :class="{leftMenu:true,transitionWidth:isCollapse}">
        <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            background-color="#304156"
            text-color="#fff"
            active-text-color="#ffd04b"
            collapse-transition
            :collapse="isCollapse"
        >

            <el-submenu v-if="userRole === 1" :index="i+1+''" v-for="(v,k,i) in menuOnj" :key="k">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>{{ k }}</span>
                </template>
                <el-menu-item :index="i+2+'-'+index" v-for="(value,index) in v" :key="index"
                              @click="showComponent(value,k)">{{ value }}
                </el-menu-item>
            </el-submenu>

            <el-submenu :index="i+2+''" v-for="(v,k,i) in menuOnj1" :key="k">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>{{ k }}</span>
                </template>
                <el-menu-item :index="i+2+'-'+index" v-for="(value,index) in v" :key="index"
                              @click="showComponent(value,k)">{{ value }}
                </el-menu-item>
            </el-submenu>


            <el-menu-item @click="enterGongYiZhongXinAction">
                <i class="el-icon-setting"></i>
                <span slot="title">公益中心管理</span>
            </el-menu-item>
            <el-menu-item @click="enterXiLiAnswerAction">
                <i class="el-icon-setting"></i>
                <span slot="title">心理答疑管理</span>
            </el-menu-item>
            <el-menu-item @click="enterJingXuanAction">
                <i class="el-icon-setting"></i>
                <span slot="title">精选文章</span>
            </el-menu-item>
            <el-menu-item @click="enterCourseAction">
                <i class="el-icon-setting"></i>
                <span slot="title">课程管理</span>
            </el-menu-item>
            <el-menu-item @click="enterZiXunYuYueAction">
                <i class="el-icon-setting"></i>
                <span slot="title">咨询预约</span>
            </el-menu-item>
            <el-menu-item @click="enterGongGaoAction">
                <i class="el-icon-setting"></i>
                <span slot="title">公告管理</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<style scoped>

.leftMenu {
    width: 250px;
    background-color: #304156;
    position: fixed;

    /*1vh是将当前设备的高度100份 一份就是vh*/
    height: 100%;
    left: 0;
    top: 0;
    transition: width 0.5s;


}

.transitionWidth {
    width: 65px;
    transition: width 0.5s;
}

/*媒介查询   就是浏览器宽高的变化 做出页面布局调整*/
@media only screen and (max-width: 1200px) {
    /*页面宽度在1000px以内才会执行此代码
    max-width:1000px   最大能监听到1000px的宽度
    */
    .left-menu {
        visibility: hidden;

    }
}

.el-menu-vertical-demo {
    border-right: none;
}
</style>