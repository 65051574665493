<template>
    <div id="app">
<!--        <Login></Login>-->
        <HomeView></HomeView>
<!--        <router-view/>-->
    </div>
</template>

<style>
* {
    margin: 0;
    padding: 0;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
}

.cf::after {
    content: '';
    display: block;
    clear: both;
}
</style>
<script>
import HomeView from "@/views/HomeView.vue";
import Login from "@/views/Login.vue";

export default ({
    components: {Login, HomeView}
})

</script>