<script>
export default {
  name: "RightContentView",
  data(){
    return{
      show:false,
      //true关闭 false打开
      dateContent:'',
      searchContent:'',

    }
  },
  props:['nav'],
  methods:{
    changeShow(){
      this.show=!this.show;
      this.$emit('changeShowEvent',this.show)
    },
    initNav(){
      // this.nav=['用户管理'];
      this.$router.push({path:'/admin/user'}).catch(()=>{})
    }
  },

}
</script>

<template>
<div :class="{rightContent:true,transitionMargin:show}">
  <div class="top cf">
   <div class="btn" @click="changeShow">
     <i v-if="show" class="el-icon-s-unfold iconSize"></i>
     <i v-else class="el-icon-s-fold iconSize"></i>
   </div>
    <div class="nav">
      <el-breadcrumb separator="/" class="spanLine">
        <el-breadcrumb-item  @click.native="initNav">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(v,i) in nav">{{v}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
  <div class="bottom">
    <router-view></router-view>
  </div>
</div>
</template>

<style scoped>
.rightContent{
  padding-left: 15px;
  /*background-color: yellow;*/
  height: 1000px;
  margin-left: 250px;
  transition: margin-left 0.5s;

}
.transitionMargin{
  margin-left: 65px;
  transition: margin-left 0.5s;

}
@media  only screen and (max-width:1200px){
  .right-content{
    margin-left: 0;
  }
}
.iconSize{
  font-size: 20px;
  vertical-align: -2px;
  margin-right: 30px;
}
.btn,.nav{
  float: left;
  line-height: 50px;
  height: 50px;
}
.top{

}
.spanLine{
  line-height: 50px;
}
/*.search{
  width: 280px;
  height: 36px;
  margin-right: 20px;
  margin-bottom: 10px;
  float: left;
}
.date{
  width: 430px;
  height: 36px;
  margin-right: 20px;
  margin-bottom: 10px;
  float: left;
}
.searchInputSize{
  box-sizing: border-box;
  width: 200px;
  height: 36px;
  padding: 0 15px;
}*/
</style>