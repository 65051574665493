import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login.vue";

Vue.use(VueRouter)

//ES6语法：file为要加载的组件的名称  懒加载函数  很重要
// const _import = (file) => () => import(`@/views/${file}.vue`);

const routes = [
    {
        path: "/",
        redirect: "/login", //在访问/界面时，自动跳转到/login界面，这里又设置为初始化界面
    },
    //完成登录组件之后，配置相应的路由信息
    {
        path: "/login", //路由路径
        name: "login", //路由名称
        component: Login      //_import("Login") //元件路径  进行懒加载  因为上面有一部分路径 所以这里可以省略一点
    },

    {  //公益中心
        path: '/homeview/gongyizhongxin',
        component: () => import('../components/GongyiComponent.vue'),
    },
    {  //心理答疑
        path: '/xinlianswer',
        component: () => import('../components/XinliAnswerComponent.vue'),
    },
    {  //精选文章
        path: '/jingxuan',
        name: 'jingxuan',
        component: () => import('../components/JingXuanComponent.vue'),
    },
    {  //课程管理
        path: '/course',
        component: () => import('../components/CourseComponent.vue'),
    },
    {   //咨询预约
        path: '/zixunyuyue',
        component: () => import('../components/ZiXunYuYueComponent.vue'),
    },
    {   //公告管理
        path: '/gonggao',
        component: () => import('../components/GongGaoComponent.vue'),
    },

    {   //用户管理
        path: '/admin/user',
        component: () => import('../components/UserComponent.vue'),
    },
    {
        //公司信息管理
        path: '/admin/company',
        component: () => import('../components/CompanyComponent.vue'),
    },
    {
        //心理评测
        path: '/zixunteacher/xinliceping',
        component: () => import('../components/XinLiCePingComponent.vue'),
    },
    {
        //心理知识
        path: '/zixunteacher/xinlizhishi',
        component: () => import('../components/XinliKnowledgeComponent.vue'),
    },
    {
        //FM
        path: '/zixunteacher/fm',
        component: () => import('../components/FMComponent.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
  console.log('beforeEach');
  if (to.path == '/login') {  //判断要去的页面是否是登录页面。是则直接放行，否则跳过本步骤，向下执行
    return next()  //这里没有传参，访问/login，next()是放行函数
  }
//   /*
//     如果sessionStorage中有数据，则会存在userInfo,再进一步对其进行判断
//       undefined -> false
//       非空字符串 -> true
//   */
  var userInfo = sessionStorage.getItem('user')

  //如果账号密码都正确，会被传入缓存中，然后就存在字符串 userInfo ，可以对其进行判断
  if (userInfo) {
    console.log('已登录，放行！');
    next()  //这里没有传参，可以访问浏览器中的地址
  } else {
    console.log('未登录，跳转到登录界面');
    return next('/login')  //这里传参，访问参数的浏览器地址
  }
})

export default router
