<script>
import LeftMenuView from "@/views/LeftMenuView.vue";
import RightContentView from "@/views/RightContentView.vue";
import Login from "@/views/Login.vue";

export default {
    name: "HomeView",
    components: {RightContentView, LeftMenuView,Login},
    data() {
        return {
            show: false,
            nav: ['']
        }
    },
    methods: {
        acceptRightShow(value) {
            this.show = value;
        },
        acceptLeftNav(arr) {
            // console.log(arr);
            this.nav = arr;
            // console.log(this.nav)
        }
    }
}
</script>

<template>
    <div class="home">
        <div v-if="$route.name !== 'login'">
            <LeftMenuView :is-collapse="show" @navigateEvent="acceptLeftNav"></LeftMenuView>
            <RightContentView @changeShowEvent="acceptRightShow" :nav="nav"></RightContentView>
        </div>
        <router-view v-else/>
    </div>

</template>

<style scoped>

</style>
<!--HomeView当个中间人传值-->